.HeaderTitle{
    color: #44474a;
    font-weight: 600;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 5px;
}
.HeaderPargraph{
    color: #737373;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;
}
.FormContainer{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    background: #f9f9f9;
}

.LoginBackground{
    width:100%
}

.FormPadding{
    padding: 1.5rem 10rem;
}

.VerifyInput{
    width: 58px;
    height: 48px;
    margin: 0px 5px;
    color: #848484;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    text-align: center;

}

.textCenter{
    cursor: pointer;
}

@media (max-width: 768px) {
    .FormPadding{padding: 2rem;}
}
