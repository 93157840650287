.InstructionItem{
    background-color: #0576e3;
    border: 2px solid #0576e3;
    border-radius: 15px;
    padding: 10px 40px;
    color: #fff;
    text-align: center;
}
.ItemNumber{
    border-radius: 50%;
    width: 30px;
    height: 25px;
    display: inline-block;
    border: 2px solid #0576e3;
    text-align: center;
    line-height: 20px;
    margin-right: 10px;
    padding: 0 5px;
}