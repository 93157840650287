.Item{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
    position: relative;
    margin: 1rem;
    background-color: #fff;
    height: 100%;
}
.Expired{
    position: absolute;
    right: 0;
    top: 0;
    display: inline;
    width: auto;
    color: #fff;
    padding: 7px 40px;
    border-radius: 0px 10px;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #0576e3;
}
.SessionImage{
    padding: 0 !important;
}
.SessionImage img{
    border-radius: 10px;
    max-width: 240px;
}
.Details{
    padding: 30px 30px !important;
}

.Details h4{
    font-weight: bold;
    font-size:18px;
    color: #7b7b7b;
}

.Details > p{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}
.Details .SessionDate{
    color: #0576e3;
    font-weight: bold;
}