.shareDropdown .dropdown-toggle::after{
  display: none;
}
.shareDropdown .dropdown-menu.show{
  box-shadow: 0 8px 24px 0 rgba(183, 183, 183, 0.17)
}
.shareDropdown .btn.show{
  border: none;
  
}
.shareDropdown .dropdown-item{
  display: flex;
  align-items: center;

}
.shareDropdown .dropdown-item .social-name{
  margin: 0 10px;
}