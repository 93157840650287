.cancel-button{
    text-align: center;
    width: 100%;
    background-color: #fff;
    outline: none;
    border: none;
    border-radius: 47px;
    padding: 15px 40px;
    font-weight: 600;
    color: #0576e3;
    border: 2px solid #0576e3;
    
}
.cancel-button:hover,
.cancel-button:active{
    background-color: #0576e3 !important; 
    color: #fff;
    border: 1px solid #0576e3;
}