.header{
    height: 220px;
    background-color: #f8f8f8;
    margin-bottom: 3rem;
}

.breadcrumb{
    flex-direction: column;
}

.breadcrumb-header{
    color:#0576e3;
    font-weight: 600;
}

.breadcrumb-details span{
    color: #a1a1a1;
    font-weight: 600;
}

.breadcrumb-details span.active-page {
    color:#0576e3;
}
