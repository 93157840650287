.course-title,
.course-header h5{
    font-weight: bold;
    color: #6e6e6e;
    
}
.course-header h5{
    margin-bottom: 2px;
}
.course-header p{
    color: #b3b3b3;
    font-weight: 500;
}
.course-description{
    color: #b3b3b3;
    font-weight: 500;
}
.course-provider{
    color: #6e6e6e;
    font-weight: normal;
    margin-bottom: 15px;
}

.course-content-sections{
    padding-right: 30px;
}

.section-container .collapse{
    padding: 10px 20px 10px 0;
}

.section-item-collapse{
    padding: 10px 20px;
    background-color: #f8f8f8;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
}

.section-title{
    margin-top: 0;
    margin-bottom: 0;
}

.section-item {
    align-items: center;
    margin: 25px 0 30px;
    cursor: pointer;
}

.section-item p{
    margin-bottom: 0;
}
.icons-container img{
    margin-right: 10px;
    width: 50px;
}
.lock-icon{
    border-radius: 7px;
    color: #0576e3;
    font-size: 1.2rem;
}

.lecture-type{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}

.live-date span{
    color: #b3b3b3;
}

@media (max-width: 768px) {
    .course-content-sections{
        padding-right: 0px;
    }
}