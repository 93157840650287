.add-story-actions p{
    font-weight: 500;
    margin: 0;
}
.add-story-actions .post{
    background-color: #e2621b;
    border-radius: 10px;
    padding: 10px 20px;
    color: #fff;
}
.story-container{
    min-height: 350px;
    position: relative;
}

.story-container svg{
    background: #fff;
    padding: 10px;
    border-radius: 45%;
    margin-bottom: 10px;
    cursor: pointer;
}
.choose-type{
    position: absolute;
    right: 1em;
}
.story-text{
    width: 100%;
    background: transparent;
    border: 0;
    text-align: center;
    border-color: transparent !important;
    outline: none;
    resize: none;
    display: flex;
    align-items: center;
    height: 200px;
    overflow: hidden;
    font-size: 20px;
}
.story-text:focus-visible{
    border-color: transparent !important;
    outline: none;
}
.color-options{
    position: absolute;
    bottom: 2rem;
}
.color-options .color{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 5px;
    cursor: pointer;
}
.text-color-type{
    justify-content: space-around;
}
.text-color-type p{
    font-weight: bold;
    cursor: pointer;
}

.FilePreview{
    border: 2px solid #0576e3;
    padding: .7rem 1rem;
    border-radius: 15px;
    margin: 2rem 0;
  }
  .FileName{
    color: #0576e3;
    font-weight: 600;
    font-size: 18px;
  }