.HeaderTitle {
  margin: 1rem 0 2rem;
  text-align: center;
  border-bottom: 1px solid #e3e3e3;
  line-height: 0.1em;
}

.HeaderTitle span {
  background: #fff;
  padding: 0 10px;
}

.HeaderPargraph {
  color: #b3b3b3;
  font-weight: 600;
}
.FormContainer {
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  
}

.FormPadding {
  padding: 2rem 2rem;
  background: #fff;
  border-radius: 8px;
}

.VerifyInput,
.EmailInput,
.PasswordInput {
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border: 1px solid #192252 !important;
  border-radius: 8px !important;
  border: none;
  color: #848484;
}

.forgetPassword {
  text-align: center;
  margin-top: 15px;
  font-size: 17px;
  text-decoration: underline;
  color: #161313;
  font-weight: 400;
}
.CreateOne {
  text-align: center;
  font-weight: 700;
  display: inline-block;
  margin-top: 1rem;
  color: #000 !important;
}
.togglePassword {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 10px;
}

.GoogleSignIn {
  padding: 14px 25px;
  border-radius: 10px;
  background-color: #fff;
  border: none;
  color: #0576e3;
  width: 100%;
  display: flex;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  margin-top: 5px;
  justify-content: center;
}

.GoogleSignIn img {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .FormPadding {
    padding: 2rem;
  }
}
.error {
  color: red;
  margin-top: 10px;
  margin-left: 10px;
}
.SignTitle{
  font-size: 30px;
  color: #101826;
  font-weight: 700;
}
.GrayTitle{
  font-size: 16px;
  font-weight: 400;
  color: #868686;
}