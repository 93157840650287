.story-card{
    box-shadow: none;
    border-radius: 10px;
    margin-bottom: 35px;
    border: none;
    cursor: pointer;
    background: #f9f9f9;
    border: 1px solid #E8E8E8;
    
}
.story-card-title {
   font-weight: 500;
    font-size: 14px;
    color: #7b7b7b;
}
.story-card .card-img-top,
.story-card > div > p{
    height: 190px;
}
.story-user{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 2px;
    background: #fff;
    margin-top: 15px;
}
svg.story-user{
    color: #fff;
    padding: 1rem;
    background: #e00;
}

@media (min-width: 769px) {
    .stories-list{
        margin-top: -10rem;
    }
}

@media (max-width: 768px) {
    .bg-story{
        display: none;
    }
}