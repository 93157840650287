.ConsultantsListSearch{
    background-color: #F9F9F9;
    border: 0;
    padding: 15px 20px 20px 25px;
    border-radius: 5px;
}

@media (max-width: 699px) {
    .ConsultantsListSearch{
        padding: 13px 20px 13px 50px;
    }
  }

