.stories-list .react-multi-carousel-track{
   margin: auto;
}

.stories-list .react-multi-carousel-item{
    padding: 0 10px;
}

.story-options{
    position: absolute;
    width: 100%;
    padding: 0 10px;
    color: #e2621b;
    top: 2rem;
    cursor: pointer;
    
}