.consultant-pages .nav-link{
    background-color: #fff;
   
    padding: 20px 30px 20px;
    font-weight: 600;
    color: #979797;
    font-size: 17px;
}
.consultant-pages .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--maincolor) ;
    background-color: #fff;
}

.top-head-sidebar{
    background: #fff;
    padding: 20px;
}
.your-balance{
    background: #eee;
    padding: 0;
    font-weight: 500;
    font-size: 19px;
    margin:0 0 15px 0;
}
.your-balance .title{
    padding: 10px 30px
}
.your-balance .value{
    background: #7b7b7b;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}

.consultant-pages li button{
    background-color: transparent !important;
}