#basic-navbar-nav{
    background: rgb(255, 255, 255);
}
#basic-nav-dropdown{
    font-weight: 500;
    font-size: 20px;
    text-decoration: none;
    padding: 0 10px;
    color: #0576e3;
}
.dropdown-menu.show a{
    color: #959393;
    padding-left: 15px;
}
@media (min-width: 1400px){
    .NavContainer{
    max-width: 1400px;
    }
}
@media (min-width: 699px){
    .DashboardDropDown{
        display: none;
    }
}
.lang{
    color: #0576e3 !important;
}
.login-register{
    padding: 15px 40px !important;
    background: #0576e3;
    color: #fff !important;
    border-color: #0576e3;
    font-size: 15px !important;
    border-radius: 40px;
   
}
.login-register-2{
    padding: 15px 40px !important;
    background: #008993;
    color: #fff !important;
    border-color: #008993;
    font-size: 15px !important;
    border-radius: 40px;
   
}
.navbar-nav{
    display: initial;
}
.join-link{
    background: #0576e3 !important;
    border: 2px solid #0576e3 !important;
    padding: 10px 25px !important;
    border-radius: 15px;
    color: #fff !important;
    
}