.Card{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 35px;
    border: none;
}
.TestName{
    color: #434343;
    font-weight: 600;
}
.Icon{
    background-color: #0576e3;
    border-radius: 10px;
    padding: 10px 15px;
}
