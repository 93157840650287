.pagination {
  text-align: center;
}
.pagination .page-item a {
  color: #0576e3;
}
.pagination .page-link {
  min-width: 50px;
    min-height: 50px;
    border-radius: 10px !important;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.pagination .active > .page-link {
  background-color: #0676E4;
  border-color: #0676E4;
}
.disabled>.page-link, .page-link.disabled{
  background-color: transparent;
}