.FormContainer {
  max-width: 100%;
}
.FormPadding {
  padding: 2rem 10rem;
}
@media (max-width: 768px) {
  .FormPadding {
    padding: 2rem;
  }
}
.FormInput {
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background: #f9f9f9;
  border: none;
  color: #848484;
}
