.HeaderTitle {
  color: #0576e3;
  font-weight: 600;
}
.TopImg {
  width: 300px;
}
.HeaderPargraph {
  color: #b3b3b3;
  font-weight: 600;
}
.FormContainer {
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  background: #f9f9f9;
}

.LoginBackground {
  width: 100%;
}

.FormPadding {
  padding: 2rem 10rem;
}

.FormInput,
.TextArea {
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color: #fff;
  border: none;
  color: #848484;
}
.FormInput {
  height: 48px;
  border-radius: 15px;
  background-color: #fff;
}

.forgetPassword {
  text-align: right;
}
.CreateOne {
  margin-left: 5px;
  color: #0576e3 !important;
}

.CheckBoxesContainer [type='radio']:checked,
.CheckBoxesContainer [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.CheckBoxesContainer [type='radio']:checked + label,
.CheckBoxesContainer [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.CheckBoxesContainer [type='radio']:checked + label:before,
.CheckBoxesContainer [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.CheckBoxesContainer [type='radio']:checked + label:after,
.CheckBoxesContainer [type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #0576e3;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.CheckBoxesContainer [type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.CheckBoxesContainer [type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.CheckBox {
  display: inline-block;
  margin-right: 15px;
}

@media (max-width: 768px) {
  .FormPadding {
    padding: 2rem;
  }
  .TopImg {
    width: 200px;
  }
}

.Drop {
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color: white;
  border: none;
  color: #848484;
}
.Drop:hover,
.Drop:active,
.btn:first-child:active,
.Drop.btn.show {
  background-color: white !important;
  color: #848484 !important;
}
