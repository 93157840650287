.custom-tabs.nav-tabs{
    border-bottom: none;
    justify-content: center;
    margin-top: 1rem;
    
}

.custom-tabs.nav-tabs .nav-item > button{
    border: 2px solid rgba(2,3,52,.102);
    margin: 0 10px;
    color: #020334;
    padding: 4px 20px 6px;
    border-radius: 15px;
    font-weight: 600;
    transition: all .5s ease 0s;
    background: 0 0;
    font-size: 20px;
    outline: none;
}
.custom-tabs.nav-tabs .nav-item > button.active{
    background: #0576e3;
    color: #fff;
    border-color: #0576e3;
}
.carouselItem{
    padding: 0 10px;
}
.join-consultants{
    background: #eaedff;
    padding: 4rem 2rem;
    border-radius: 20px;
}
.custom-tab-button{
    border: 2px solid rgba(2,3,52,.102);
    margin: 0 10px;
    color: #020334;
    padding: 4px 20px 6px;
    border-radius: 5px;
    font-weight: 600;
    transition: all .5s ease 0s;
    background: 0 0;
    font-size: 20px;
    outline: none;
}

.custom-tab-button.active{
    background: #0576e3;
    color: #fff;
    border-color: #0576e3;
}