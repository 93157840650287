.course-card{
    /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
    box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 10px;
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;
    cursor: pointer;
}
.course-card-title {
    font-weight: bold;
    font-size: 18px;
    color: #7b7b7b;
}
.course-provider{
    color: #0576e3;
    font-weight: bold;
  }