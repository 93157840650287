.course-item{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 9px;
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
}
.course-image{
    padding: 0 !important;
}
.course-image img{
    border-radius: 10px;
    width: 220px;
     height: 200px;
    }
.course-details{
    padding: 30px 40px !important;
}

.course-details h4{
    font-weight: bold;
    font-size:18px;
    color: #7b7b7b;
}

.course-details > p{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}
.course-details .course-provider{
    color: #0576e3;
    font-weight: bold;
}



.rate-number{
    color: #7b7b7b;
    font-weight: 600;
    font-size: 1.15rem;
    margin-left: 10px;
}

.course-views{
    color: #b3b3b3;
    font-weight: 500;
    font-size: 1.2rem;
}
.course-views-icon{
    margin-right: 5px;
}
.course-price{
    font-size: 1.3rem;
    font-weight: bold;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.course-price .discount-price{
    color:#0576e3;
}

.course-price .real-price{
    text-decoration: line-through;
    color: #b3b3b3;
    text-decoration-thickness: 2px;
}