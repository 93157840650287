.ConsultantBtnAction {
  color: #fff;
  width: 100%;
  display: flex;
  text-align: center;
  border-radius: 15px;
  padding: 10px 30px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.ConsultantBtnAction img {
  width: 30px;
}
