/* VideoPlayer.css */

.video-player {
    position: relative;
    width: 100%;
    max-width: 800px; /* Adjust as needed */
  }
  
  .video-player video {
    width: 100%;
    display: block;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and opacity */
  }
  
  .overlay img {
    width: 80px; /* Adjust the play button size */
    cursor: pointer;
  }
  
  .overlay img:hover {
    opacity: 0.8; /* Adjust the hover opacity of the play button */
  }