.ListSearch{
    background-color: #f7f7f7;
    border: 0;
    padding: 15px 25px 15px 60px;
    border-radius: 15px;
}

.TopHead{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;
    position: relative;
}

.FilterContainer{
    position: absolute;
    right: 0;
    width: fit-content;
    top: 0;
    cursor: pointer;
}
.Filter{
    width: 50px;
    background: #eaedff;
    border-radius: 10px;
    padding: 10px 12px 10px 10px;
    position: relative;
    cursor: pointer;
}

@media (min-width:700px) {
    .FilterContainer{
        display: none;
    } 
}

.ConsultantsListSearch{
    background-color: #F9F9F9;
    border: 0;
    padding: 15px 20px 20px 25px;
    border-radius: 5px;
}

@media (max-width: 699px) {
    .ConsultantsListSearch{
        padding: 13px 20px 13px 50px;
    }
  }