.Item{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
    position: relative;
    padding: 0.5rem 1rem;
}
.Image img{
    border-radius: 10px;
    width: 150px;
    height: 150px;
    max-width: 220px;
}
.Languages span,
.Languages p
{
    margin: 0 5px 0 0;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
}
.Languages span:nth-child(odd),
.Languages p{
    background: #FFB448;
}

.Languages span:nth-child(even){
    background: #0576e3;
}
.Languages p{
    padding: 4px 8px;
}
.Availabilty{
    padding: 4px 20px;
    border: 1px solid #9bf3f8;
    font-size: 1rem;
    border-radius: 5px;
    display: inline-block;
    background: #9bf3f8;
    color: #0576e3;
}

@media (max-width: 699px) {
    .Image img{
        width: 100px;
        height: 100px;
    }    
    .Item h5,
    .Languages span,
    .Languages p,
    .Availabilty{
        font-size: 14px !important;
    }
    

}