.ActionBtns button{
    background-color: #fff;
    flex-grow: 1;
    border-radius: 15px;
    padding: 15px;
    font-size: 22px;
    margin: 10px;
}


.ActionBtns button:first-child{
    color:#0576e3 ;
    border: 1px solid #0576e3;
}

.ActionBtns button:last-child{
    color:#0576e3 ;
    border: 1px solid #0576e3;
}