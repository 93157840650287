.consultant-card {
 /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
  box-shadow: none;
  border-radius: 10px;
  margin-bottom: 35px;
  border: none;
  cursor: pointer;
  /* min-height: 500px; */
}
.consultant-card-title {
  font-weight: bold;
  font-size: 18px;
  color: #7b7b7b;
}
.consultant-provider {
  color: #0576e3;
  font-weight: bold;
}
.consultant-rate .rate-stars {
  margin: 0;
}
