.course-details-card {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;
}

.card-img-container{
    overflow: hidden;
    height: 240px;
    position: relative;
    border-radius: 10px;
}
.card-img-container img{
    width: 100%;
    height: auto;
}
.course-body{
    padding: 20px 35px;
}
.course-body h5{
    font-weight: bold;
    margin-bottom: 0;
    font-size: 1.1rem;
    color: #7b7b7b;
}
.course-features{
    margin-top: 5px;
}
.course-features p {
    margin: 0 0 5px 0;
}
.course-features p span {
    margin-right: 5px;
}
.discount-price{
    font-size: 1.3rem;
    color: #0576e3;
    font-weight: bold;
}

.real-price {
    text-decoration: line-through;
    text-decoration-thickness: auto;
    color: #b3b3b3;
    text-decoration-thickness: 2px;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
}
.discount-percentage{
    color: #0576e3;
    font-weight: bold;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.2);
}
.preview-icon{
    color: white;
    font-size: 2rem;
}

.icon-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: rgba(255,255,255, 0.3);
    padding: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
}

@media (max-width: 768px) {
    .course-details-card{
        margin-top: 0;
        position: inherit;
        width: 100%;
    }
    
}

