.card-button{
    text-align: center;
    width: 100%;
    background-color: #0576e3;
    outline: none;
    border: 2px solid #0576e3;
    border-radius: 47px;
    padding: 15px 40px;
    font-weight: 600;
    
}

.card-button:hover,
.card-button:active{
    background-color: #e2621b !important; 
    border: 2px solid #e2621b;
}