.section-title{
    font-weight: 600;
}
.progress-bar{
    padding: 10px 0px;
}
.progress {
    --bs-progress-bar-bg: #0576e3;
}
.courseRate{
    font-weight: 600;
    margin-bottom: 0;
    color: #7b7b7b;

}
.starts-percentage{
    font-weight: bold;
    color: #7b7b7b;
}
