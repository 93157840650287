.Item{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
    position: relative;
    margin: 0 1rem 2rem;
    padding: 1rem;
}
.Owner{
    position: absolute;
    right: 0;
    top: 0;
    display: inline;
    width: auto;
    color: #fff;
    border-radius: 0px 10px;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #0576e3;
}
.Level{
    color: #0576e3;
    display: inline-block;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    font-size: 1.1rem;
    background: #e4fdff;
}
.Image{
    padding: 0 !important;
}
.Image img{
    border-radius: 10px;
    max-width: 240px;
    border-radius: '10px';
}
.Details{
    padding: 30px 5px;
}

.Details h4{
    font-weight: 500;
    font-size:18px;
    color: #0576e3;
    margin: 15px 0 10px;
}

.Details > p{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}
.Details .Date{
    color: #0576e3;
    font-weight: bold;
}
.Price{
    color: #fff;
    background-color: #0576e3;
    display: inline-block;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    font-size: 1.1rem;
}

@media only screen and (max-width: 699px) {
    .Image img{
        max-width: 100% !important;
        width: 100% !important;
        height: 110px !important;
    }

    .Details{
        padding: 10px 5px;
    }
    .Date > span{
        font-size: 12px;
    }
    .Date > span > img{
        margin-right: 5px !important;
        width: 17px !important;
    }
    .Price,
    .Level{
        font-size: 12px;
    }
}